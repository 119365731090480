<template lang="pug">
main.main-screen
    .container
        transition(name='fade')
            IntroScreen(v-if='view === "intro"')
            LevelIntroScreen(v-if='view === "level"')
            GameScreen(v-if='view === "game"')
            ScoreScreen(v-if='view === "score"')
            FormScreen(v-if='view === "form"')
        Result(v-if='isResult')

    Background
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['view', 'isResult']),
    },

}
</script>

<style lang="scss" scoped>
.main-screen{
    background-color: black
}
.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    min-height: 600px;
    z-index: 1;
}


</style>

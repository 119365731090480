<template lang="pug">
.level-intro.screen(v-if='!loading')
    header
        img.pb-3(v-if='level === 2', src='/imgs/title-level-2.png')
        img.pb-3(v-else src='/imgs/title-level-1.png')
    section
        //- p(v-html='data.copy')
        span(v-show='levelPage === 1')
            img(:src='data.page1.image', alt='')
            p(v-html='data.page1.copy')
        span(v-show='levelPage === 2')
            img(:src='data.page2.image', alt='')
            p(v-html='data.page2.copy')
        span(v-show='levelPage === 3')
            img(:src='data.page3.image', alt='')
            p(v-html='data.page3.copy')
        span(v-show='levelPage === 4')
            img(:src='data.page4.image', alt='')
            p(v-html='data.page4.copy')
        span(v-if='levelPage === 5')
            img(:src='data.page5.image', alt='')
            p(v-html='data.page5.copy')
        span(v-if='levelPage === 6')
            img(:src='data.page6.image', alt='')
            p(v-html='data.page6.copy')
    footer
        span(v-if='levelPage === 6')
            button(
                @click='handleView("next-game")',
                v-html='data.page6.button',
            )
        span(v-else)
            button(
                @click='handleView("next-level-page")',
                v-html='data.button',
            )
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            dataL1: 'levelIntroScreenDataLevel1',
            dataL2: 'levelIntroScreenDataLevel2',
        }),
        ...mapState(['level', 'levelPage', 'loading']),
        data() {
            return this.level === 2
                ? this.dataL2
                : this.dataL1
        },
    },
    methods: {
        handleView(val) {
            this.$store.commit('handleView', val)
        },
    },
}
</script>

<style lang="sass" scoped>
header
    img
        height: 100%
        max-height: 90px
section
    //background: radial-gradient(black, transparent)
    p
        font-weight: 600
        padding: 2rem 12rem
        &:nth-of-type(2)
            font-weight: 600
    img
        max-height: 225px
footer
    span
        justify-content: center
        display: inline-flex
</style>

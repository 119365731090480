<template lang="pug">
.score-screen
    button(@click='handleView("next-restart")') Go Back
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState([]),
    },
    methods: {
        handleView(val) {
            this.$store.commit('handleView', val)
        },
    },
}
</script>

<style lang="sass" scoped>
p
    font-size: 5rem
    color: white
button
    width: 300px
</style>
<template>
    <div style="">
            <div class="intro top-zero text-3xl" v-if="confirmation">
                <div class="text-2xl leading-normal p-12 text-3xl text-center text-black" style="max-width: 960px;">
                    <img class="w-1/4 mx-auto" src="/imgs/matchup_logo.png" alt=""/>
                    <h1 class="font-black mb-8 mt-8 sm:text-4xl lg:text-5xl">
                        <span v-if="client == 'comcast'">You’ve earned your spot!</span>
                        <span v-else>You’ve earned your spot!</span>
                    </h1>
                     <div class="mb-10 text-4xl font-semibold">
                            Play again to see how high you can climb the on The SHOWTIME Movie Matchup Wall of Fame! Watch boundary-pushing films like Everything <i>Everywhere All at Once</i> and <i>Midsommar</i> now with SHOWTIME ON DEMAND<sup>®</sup>.
                    </div>

                     <div class="flex mx-auto w-full justify-around">
                        <!-- <div class="bg-gray-800 cursor-pointer flex hover:bg-gray-600 items-center justify-around text-center text-white uppercase w-5/12 h-24 pt-1" @click="playAgain()">Play Again</div> -->
                        <button class="w-1/3 flex justify-around" @click="playAgain()">Play Again</button>
                        <button class="w-1/2 flex justify-around" @click="goLeaderboard()">Go To the Wall of Fame</button>
                    </div>
                </div>
            </div>
            <div v-else style="max-width: 1100px;" class="z-20 bg-black h-full py-6 mx-auto text-3xl text-white">
                <div class="" v-if="this.$root.email !== ''">

                </div>
                <div class="" v-else>
                    <div class="form-wrap mt-6">
                        <div class="mb-3">
                            <div class="flex w-11/12 mx-auto">
                                <div class="mx-auto">
                                    <h3 class="h3 text-4xl">Add your name to the <strong class="text-show-red">The SHOWTIME Movie Matchup</strong> Wall of Fame</h3>
                                </div>
                            </div>
                                <!-- <div class="w-12/12 mx-auto text-center">
                                    <h5 class='h4 text-4xl' id='code'>CONFIRMATION CODE: <span class="text-show-teal font-bold inline">{{ randomCode }}</span></h5>
                                </div> -->
                        </div>
                        <div>
                            <p id="error-text"></p>
                        </div>
                        <form action="#" @submit.stop.prevent="sendData" class="text-left">
                        <div class="w-11/12 flex mx-auto">
                            <div class="w-100">
                                <h2 class='section-label class="text-2xl mb-1 block mt-2" font-bold mb-2 mt-2 text-4xl text-show-teal'>Employee Info</h2>
                                    <div class="flex">
                                        <div class="w-1/2 mx-3">
                                            <label class="text-2xl mb-1 block mt-2" for="agentFirst">Your First Name</label class="text-2xl mb-1 block mt-2">
                                            <input type="text" name="agentFirst" id="agentFirst" class="requiredField form-text-input shadow appearance-none border rounded w-full pt-3 pb-2 px-3 text-gray-700 leading-tight text-2xl" placeholder="Agent First">
                                        </div>
                                        <div class="w-1/2 mx-3">
                                            <label class="text-2xl mb-1 block mt-2" for="agentLast">Your Last Name</label class="text-2xl mb-1 block mt-2">
                                            <input type="text" name="agentLast" id="agentLast" class="requiredField form-text-input shadow appearance-none border rounded w-full pt-3 pb-2 px-3 text-gray-700 leading-tight text-2xl" placeholder="Agent Last">
                                        </div>
                                        <div class="w-3/5 mx-3">
                                            <label class="text-2xl mb-1 block mt-2" for="empEmail">Your Work Email</label class="text-2xl mb-1 block mt-2">
                                            <input type="text" name="empEmail" id="empEmail" class="requiredField form-text-input shadow appearance-none border rounded w-full pt-3 pb-2 px-3 text-gray-700 leading-tight text-2xl" placeholder="name@company.com">
                                        </div>
                                    </div>

                                    <div class="flex mt-6">
                                        <!-- <div class="w-2/5 mx-3">
                                            <label class="text-2xl mb-1 block mt-2" for="empDept">Department</label class="text-2xl mb-1 block mt-2">
                                            <input type="text" name="empDept" id="empDept" class="requiredField form-text-input shadow appearance-none border rounded w-full pt-3 pb-2 px-3 text-gray-700 leading-tight text-2xl" placeholder="Department">
                                        </div> -->
                                    </div>
                            </div>
                            <!-- <div class="w-1/2">
                                    <h2 class='section-label class="text-2xl mb-1 block mt-2" font-bold mb-2 mt-2 text-4xl text-show-teal'>Supervisor Info</h2>
                                    <div class="flex">
                                        <div class="w-1/2 mx-3">
                                            <label class="text-2xl mb-1 block mt-2" for="superFirst">Supervisor First Name</label class="text-2xl mb-1 block mt-2">
                                            <input type="text" name="superFirst" id="superFirst" class="requiredField form-text-input shadow appearance-none border rounded w-full pt-3 pb-2 px-3 text-gray-700 leading-tight text-2xl" placeholder="Supervisor First">
                                        </div>
                                    <div class="w-1/2 mx-3">
                                            <label class="text-2xl mb-1 block mt-2" for="superLast">Supervisor Last Name</label class="text-2xl mb-1 block mt-2">
                                            <input type="text" name="superLast" id="superLast" class="requiredField form-text-input shadow appearance-none border rounded w-full pt-3 pb-2 px-3 text-gray-700 leading-tight text-2xl" placeholder="Supervisor Last">
                                        </div>
                                    </div>
                                    <div class="flex mx-3 mt-6">
                                        <div class="w-full">
                                            <label class="text-2xl mb-1 block mt-2" for="superEmail">Supervisor Work Email</label class="text-2xl mb-1 block mt-2">
                                            <input type="text" name="superEmail" id="supEmail" class="requiredField form-text-input shadow appearance-none border rounded w-full pt-3 pb-2 px-3 text-gray-700 leading-tight text-2xl" placeholder="supervisor@company.com">
                                        </div>
                                    </div>
                            </div> -->
                        </div>
                        <!-- ROW// -->

                        <div class="w-11/12 mx-auto">
                            <hr class="w-1/2 mx-auto my-8" style="border-color:#2b8895;">
                            <h2 class='section-label class="text-2xl mb-1 block mt-2" font-bold mb-2 mt-2 text-4xl text-show-teal'>Call Center Location</h2>
                                <p class='form-p leading-normal m-b-1 m-l-1 mb-1 ml-1 text-2xl'>You must select your company first,<em> then</em> your state, then your location. If your location is not listed please email your information to this address: <a href="mailto:SHOpromotion@getrenegade.com?subject=%Showtime%20Bad%20Decision%20Bingo%20Submission">SHOpromotion@getrenegade.com</a>.</p>
                                <div class="w-full flex">
                                    <div class="w-2/5 mx-3">
                                        <label class="text-2xl mb-1 block mt-2" for="company">Company</label class="text-2xl mb-1 block mt-2">
                                        <select type="select" class="form-select-input appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 pt-3 pb-2 px-4 pr-8 rounded leading-tight text-2xl" onchange="setUpStates()" name="company" id="company" value="">
    <!--                                        @if($client=="att")
                                            <option value="none">Choose Your Country</option>
                                            @else -->
                                            <option value="none">Choose Your Company</option>
                                            <!-- @endif -->
                                        </select>
                                    </div>
                                    <div class="w-1/5 mx-3">
                                        <label class="text-2xl mb-1 block mt-2" for="state">State</label class="text-2xl mb-1 block mt-2">
                                        <select type="select" class="disabled form-select-input appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 pt-3 pb-2 px-4 pr-8 rounded leading-tight text-2xl requiredField" disabled="true" name="state" onchange="setupAddress()" id="state" value="">
                                            <option value="none">ST</option>
                                        </select>
                                    </div>
                                    <div class="w-2/5 mx-3">
                                        <label class="text-2xl mb-1 block mt-2" for="address">Address</label class="text-2xl mb-1 block mt-2">
                                        <select type="select" class="disabled form-select-input appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 pt-3 pb-2 px-4 pr-8 rounded leading-tight text-2xl requiredField" disabled="true" name="address" id="address" onchange="selectAddress()" value="">
                                            <option value="none">Address</option>
                                        </select>
                                        <!-- ROW// -->
                                    </div>
                                </div>

                        </div>
                        <div class="w-11/12 mx-auto">
                            <div class="mt-1">
                                <hr class="w-1/2 mx-auto my-8" style="border-color:#2b8895;">
                                <div class="flex justify-content-center">
                                    <div class="w-1/2">
                                        <!-- <div class="mr-3 pb-3 pt-1 custom-checkbox h-24" style=" float:left; clear:left">

                                            <input type="checkbox" class="w-8 h-8" id="emailOpt" checked  name="emailOpt" value="1">
                                        </div>
                                        <div class="mb-3">
                                            <p class="pt-1 text-3xl leading-snug">Yes, I would like to receive emails about incentive promotions and engagement opportunities from SHOPlace.</p>
                                        </div> -->
                                        <div>
                                            <div class="mr-3 pb-3 pt-1 custom-checkbox h-24" style=" float:left; clear:left">
                                                <input type="checkbox" class="w-8 h-8" id="displayName" checked name="displayName" value="1">
                                            </div>
                                            <div>
                                                <p class="pt-1 text-3xl leading-snug">Yes, please post my name on the Wall of Fame.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="w-1/2">
                                        <p class="text-2xl " style="padding-bottom: 3px;">Please send my SHOWTIME updates to:</p>
                                        <input type="text" name="updatesEmail" id="updatesEmail" class="requiredField form-text-input shadow appearance-none border rounded w-full pt-3 pb-2 px-3 text-gray-700 leading-tight text-2xl">
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="w-11/12 mx-auto mt-6">
                            <div class="w-1/2 mx-auto">
                                <input type="submit" value="SUBMIT" id="form-submit" disabled="disabled" class="block font-bold bg-gray-700 text-2xl h-18 flex mx-auto px-16 pt-4 pb-3 hover:bg-gray-600 disabled-btn"/>
                                <!-- <p class="text-center text-2xl mt-4">You must click "SUBMIT" to enter.</p> -->
                            </div>

                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
    export default {
        data() {
            return {
                showForm: true
            }
        },
        mounted() {
            console.log('Mounted');
            setTimeout(() => {
                setupCompanies()
            }, 1000);
        },
        methods:{
            sendData(event){
                event.preventDefault();
                event.stopPropagation();
                processForm(this.timeScore, this.timeString);
                return false;
            },
            goSho(){
                window.open('https://shoplace.com')
            },
            goLeaderboard(){
                window.location = '/'+this.$root.client+'/leaderboard'
            },
            playAgain(){
                 this.$store.dispatch('handleView', "next-restart")
            }
         },
         computed:{
            randomCode: function() {
                var text = "";
                var possible = "PERFCTAIS123456789";

                for (var i = 0; i < 4; i++)
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                return "HS" + text + Math.round(Math.random()) +"2";
            },
            confirmation(){
               return this.$root.confirmation
            },
            client(){
                return this.$root.client
            },
            timeScore(){
                return this.$store.state.pauseTime;
            },
            timeString(){
                return this.$store.state.timerString;
            }
         }
    }
</script>
<style scoped>

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.checkbox-wrapper {
    float: left;
    width: 15px;
}
input[type="checkbox"] {
    -webkit-appearance: checkbox;
}
button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled){
    background: var(--sho-red);
}
</style>

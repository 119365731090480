<template lang="pug">
.background.fade-in-bg
    transition(name='fade-bg')
        .intro.full-image
        //- .intro.right-image(v-if='view === "intro"')
    //- transition(name='fade-bg')
    //-     .intro.left-image(v-if='view === "intro"')
    //- transition(name='fade-bg')
    //-     .level-1.right-image(v-if='level === 1')
    //- transition(name='fade-bg')
    //-     .level-1.left-image(v-if='level === 1')
    //- transition(name='fade-bg')
    //-     .level-2.right-image(v-if='level === 2')
    //- transition(name='fade-bg')
    //-     .level-2.left-image(v-if='level === 2')
    //- transition(name='fade-bg')
    //-     .level-3.right-image(v-if='level === 3')
    //- transition(name='fade-bg')
    //-     .level-3.left-image(v-if='level === 3')
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['view', 'level', 'levelPage']),
    },
}
</script>

<style lang="sass" scoped>
.background
    div
        position: absolute
        inset: 0
        background-size: cover
        //background-position: center center
        z-index: 0
        &.intro
            background-image: url('/imgs/bg-level-1.jpg')
        &.level-intro-1
            background-image: url('/imgs/bg-level-1-2.jpg')
        &.level-1
            background-image: url('/imgs/bg-level-1-2.jpg')
        &.level-2
            background-image: url('/imgs/bg-level-2-2.jpg')
        &.level-3
            background-image: url('/imgs/bg-level-3-2.jpg')
        &.full-image
            width:100%
            background-position: center center
        &.left-image
            width:50%
            background-position: center right
        &.right-image
            width:50%
            background-position: center left
            left: 50%
</style>

<template lang="pug">
.game.screen(:class='{ level1: level === 1, disabled }')
    header
        img(v-if='level === 1', src='/imgs/title-level-1.png')
        img(v-if='level === 2', src='/imgs/title-level-2.png')
        img(v-if='level === 3', src='/imgs/title-level-3.png')
    section
        .game-board
            .tile.over.none(
                v-for='(tile, id) in data',
                :id='id',
                :data-tile='tile.data',
                @click='handleMatch($event)'
            )
                img(:src='tile.image')
                img(:src='tile.correct')
                img(:src='tile.incorrect')
                img(:src='tile.over')
    footer
    timer(v-if='view == "game"', ref='timer')
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            dataL1: 'gameScreenDataLevel1',
            dataL2: 'gameScreenDataLevel2',
            dataL3: 'gameScreenDataLevel3',
        }),
        ...mapState(['level', 'disabled', 'refresh', 'view']),
        data() {
            this.refresh
            let tiles = {}
            const obj = () =>
                this.level === 3
                    ? this.dataL3.tiles
                    : this.level === 2
                    ? this.dataL2.tiles
                    : this.dataL1.tiles
            const keys = Object.keys(obj())
            keys.sort(() => 0.5 - Math.random())
            keys.forEach((k) => (tiles[k] = obj()[k]))
            return tiles
        },
    },
    methods: {
        handleView(val) {
            this.$store.commit('handleView', val)
        },
        handleMatch(event) {
            this.$store.commit('handleMatch', event)
        },
    },
}
</script>

<style lang="sass" scoped>
.game.screen
    max-width: 1000px
    header
        margin-top:45px
    // &.level1
    //     // max-width: 800px
    //     .game-board
    //         grid-template-columns: repeat(4, 1fr)
.game-board
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-template-rows: repeat(3, 1fr)
    grid-column-gap: 2rem
    grid-row-gap: 2rem
    margin: 2rem 0
    .tile
        border: 2px solid white
        cursor: pointer
        img:nth-child(1)
            display: block
        img:nth-child(2)
            display: none
        img:nth-child(3)
            display: none
        img:nth-child(4)
            display: none
        &.correct
            border: 2px solid var(--sho-correct)
            pointer-events: none
            img:nth-child(1)
                display: none
            img:nth-child(2)
                display: block
            img:nth-child(3)
                display: none
            img:nth-child(4)
                display: none
        &.incorrect
            border: 2px solid var(--sho-incorrect)
            pointer-events: none
            img:nth-child(1)
                display: none
            img:nth-child(2)
                display: none
            img:nth-child(3)
                display: block
            img:nth-child(4)
                display: none
        &.over
            img:nth-child(1)
                display: none
            img:nth-child(2)
                display: none
            img:nth-child(3)
                display: none
            img:nth-child(4)
                display: block
footer
    span
        padding-top: 1rem
        color: white
</style>

<template>
    <div class="timer py-2 text-white mx-auto flex-wrap">
        <div>{{minutes}}</div>:
        <div>{{seconds }}</div>:
        <div>{{milliseconds}}</div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    ...mapState(['timerState']),
    data() {
        return {
            seconds: 0,
            milliseconds: 0,
            minutes: 0,
            timer: Object,
            quizTimer: Object,
            elapsedTime: Object,
            startTime:Object,
            timeString:'',
            t:Object,
            v:Object,
            timeScore:Object,
        }
    },
    mounted(){


    },
    watch: {},
    methods: {
        goTime() {
            console.log('timing!')
            this.timeStart = new Date().getTime();
            this.quizTimer = setInterval(this.setTimer, 1);
        },
        setTimer() {
            var u = new Date().getTime();
            this.t = (u - this.timeStart);
            var tmilliseconds = Math.floor((this.t / 10) % 100);
            var tseconds = Math.floor((this.t / 1000) % 60);
            var tminutes = Math.floor((this.t / 1000 / 60) % 60);
            this.elapsedTime = {
                'total': this.t,
                'minutes': tminutes,
                'seconds': tseconds,
                'milliseconds': tmilliseconds
            }
            this.updateTime()
            //this.seconds = this.elapsedTime.seconds;
            //this.milliseconds = this.elapsedTime.milliseconds;
        },
        updateTime() {
            if (this.elapsedTime.minutes < 10) {
                this.minutes = '0' + this.elapsedTime.minutes;
            } else {
                this.minutes = this.elapsedTime.minutes;
            }
            if (this.elapsedTime.seconds < 10) {
                this.seconds = '0' + this.elapsedTime.seconds;
            } else {
                this.seconds = this.elapsedTime.seconds;
            }

            if (this.elapsedTime.milliseconds < 10) {
                this.milliseconds = '0' + this.elapsedTime.milliseconds;
            } else {
                this.milliseconds = this.elapsedTime.milliseconds;
            }
        },
        restartTime () {
            this.v = new Date().getTime();
            this.timeStart = this.v - this.pauseTime;
            console.log(this.v-this.pauseTime);
            this.quizTimer = setInterval(this.setTimer, 1);
        },
        stopTime() {
            clearInterval(this.quizTimer);
            this.timeScore = this.t;
            this.$store.dispatch('setPauseTime', this.t)
            this.$store.dispatch('setTimeString', this.parseTime())
        },
        parseTime() {
            if (this.elapsedTime.minutes < 10) {
                var dbMin = '0' + this.elapsedTime.minutes + ":"
            } else {
                var dbMin = this.elapsedTime.minutes + ":"
            }
            if (this.elapsedTime.seconds < 10) {
                var dbSec = '0' + this.elapsedTime.seconds + ":"
            } else {
                var dbSec = this.elapsedTime.seconds + ":"
            }

            if (this.elapsedTime.milliseconds < 10) {
                var dbMill = '0' + this.elapsedTime.milliseconds;
            } else {
                var dbMill = this.elapsedTime.milliseconds;
            }
            return dbMin + dbSec + dbMill;
        }
    },
    watch:{
        timerStateWatch: function( newState, oldState){
            switch(newState){
                case 'paused':
                    this.stopTime()
                    break;
                case 'restart':
                    this.$nextTick(()=>{
                        this.restartTime();
                    })
                    break;
                case 'running':
                    this.goTime();
            }
        }
    },
    computed:{
        timerStateWatch(){
            return this.$store.state.timerState;
        },
        level(){
            return this.$store.state.level;
        },
        pauseTime(){
            return this.$store.state.pauseTime;
        }
    }
}
</script>

<style lang="sass" scoped>

.timer
    display: flex
    align-items: center
    justify-content: center
    width: 26%
    font-size: 3rem
    font-weight: 300
    // bottom: 2%
    // position: absolute

@media (max-height: 800px)
    .timer
        font-size:2.5rem
        height:30px
</style>

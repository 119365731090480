/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap')
window.Vue = require('vue').default


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key =>
    Vue.component(
        key
        .split('/')
        .pop()
        .split('.')[0],
        files(key).default
    )
)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import { store } from './store'
window.app = new Vue({
    el: '#app',
    store,
    data: {
        globalUrl: document.head.querySelector('meta[name="globalUrl"]').content,
        client: document.head.querySelector('meta[name="client"]').content,
        email: '',
        confirmation: false
    },
    created() {},
    mounted() {
        this.preLoadImages()
    },
    methods: {
        preLoadImages() {
            const dir = '/imgs/'
            const imgs = [
                `${dir}bg-level-1.jpg`,
                `${dir}character-1-1.png`,
                `${dir}character-1-2.png`,
                `${dir}character-1-3.png`,
                `${dir}character-1-4.png`,
                `${dir}character-1-5.png`,
                `${dir}character-1-6.png`,
                `${dir}gif-1-1.gif`,
                `${dir}gif-1-2.gif`,
                `${dir}gif-1-3.gif`,
                `${dir}gif-2-1.gif`,
                `${dir}gif-2-2.gif`,
                `${dir}gif-2-3.gif`,
                `${dir}gif-end.gif`,
                `${dir}character-2-1.png`,
                `${dir}character-2-2.png`,
                `${dir}character-2-3.png`,
                `${dir}character-2-4.png`,
                `${dir}character-2-5.png`,
                `${dir}character-2-6.png`,
            ]
            imgs.forEach(path => {
                let img = new Image()
                img.src = path
            })
        }
    },
    computed: {
        isIE: function() {
            if (navigator.userAgent.match(/Trident.*rv\:11\./)) {
                return true
            } else {
                return false
            }
        }
    }
})
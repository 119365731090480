<template lang="pug">
.result-modal(@click='!levelWon && handleView("close-result")')
    span.win(v-if='levelWon')
        header
            h1(v-html='data.title')
        section(v-if='levelWon')
            img(:src='data.image', v-show='level === 2')
            p.final.font-semibold(v-if='level === 2') You matched every movie in</br>
                strong.text-6xl {{ finalTime }}
                span.break
                span(v-if='client === "comcast"') You did it! Now see how your time matches up to other players on The SHOWTIME Movie Matchup Wall of Fame, or play again to see how fast you can beat The SHOWTIME Movie Matchup!
                span(v-else) You did it! Now see how your time matches up to other players on The SHOWTIME Movie Matchup Wall of Fame, or play again to see how fast you can beat The SHOWTIME Movie Matchup!
            p.font-semibold(v-else, v-html='data.copy')
        footer
            span(v-if='level === 2')
                button.wider(
                    v-if='level === 2',
                    @click='playAgain()',
                ) PLAY AGAIN
                button.wider(
                    v-if='level === 2',
                    @click='handleView("show-form")',
                    v-html='data.button'
                )
            span(v-else)
                button(
                    @click='handleView("next-level")',
                    v-html='data.button'
                )
    .correct(v-else)
        img(:src='gif')
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['resultScreenData']),
        ...mapState(['level', 'gif', 'levelWon', 'isResult', 'pauseTime']),
        data() {
            return this.level === 2
                ? this.resultScreenData.level2
                : this.resultScreenData.level1
        },
        finalTime() {
            return this.$store.state.timerString
            // const round = Math.round(Math.round(this.pauseTime * 100) / 100000)
            // if (round > 60) {
            //     return (round / 60).toFixed(2) + ' Minutes'
            // } else {
            //     return round + ' Seconds'
            // }
        },
        client() {
            return this.$root.client
        },
    },
    methods: {
        handleView(val) {
            this.$store.commit('handleView', val)
        },
        playAgain(){
            this.$store.dispatch('handleView', "next-restart")
        }
    },
}
</script>

<style lang="sass" scoped>
.result-modal
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    width: 100%
    height: 100%
    z-index: 99
    .win
        width: 80%
        border: 1px solid white
        box-shadow: 0 0 800px 200px rgb(0 0 0 / 0.5)
        header
            h1
                font-size: 5rem
                padding: 4rem 2rem 0
                text-transform: uppercase
                font-weight: 700
                color: var(--sho-red)
        section
            img
                max-width: 250px
            p.final
                padding: 2rem 4rem
                font-weight: 600
        footer
            span
                padding: 0 2rem 2rem
                justify-content: center
                display: inline-flex
        & > *
            width: 100%
            background: rgb(245 245 245 / 0.95)
    .correct
        position: relative
        margin-top: -5rem
        border: 6px solid rgba(0, 0, 0, 0.55)
        box-shadow: 0 0 800px 200px rgb(0 0 0 / 0.5)
        &::before
            content: '\2715'
            position: absolute
            width: 30px
            height: 30px
            margin: 4px
            right: 0
            font-size: 2.5rem
            color: white
            cursor: pointer
        img
            max-width: 450px
            border: 1px solid white
.wider
    width: 35%
</style>

<template lang="pug">
.intro.screen.fade-in.pt-12
    header
        img(v-if='isIntro', src='/imgs/matchup_logo.png', alt='')
        img.howto(v-if='isHowto', src='/imgs/how-to-logo.png', alt='')
    section(v-if='client === "comcast"')
        p(v-if='isIntro', v-html='data.copy1Comcast')
        p(v-if='isHowto', v-html='data.copy2Comcast')
        //- img(v-if='isHowto', :src='data.imageComcast', alt='')
    section(v-else)
        p(v-if='isIntro', v-html='data.copy1')
        p(v-if='isHowto', v-html='data.copy2')
        //- img(v-if='isHowto', :src='data.image', alt='')
    footer
        span
            button(
                v-if='isIntro',
                @click='handleView("next-howto")',
                v-html='data.button1'
            )
            button.score(
                v-if='isIntro',
                @click='goToLeaderBoard',
                v-html='data.button2'
            )
            button.howto(
                v-if='isHowto',
                @click='handleView("next-level-intro")',
                v-html='data.button3'
            )
        p Watch Oscar®-winners like the mind-bending <i> Everything Everywhere All at Once </i> now with SHOWTIME ON DEMAND<sup>®</sup>.
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({ data: 'introScreenData' }),
        ...mapState(['isIntro', 'isHowto']),
        client() {
            return this.$root.client
        },
    },
    methods: {
        handleView(val) {
            this.$store.commit('handleView', val)
        },
        goToLeaderBoard() {
            window.location = '/' + this.$root.client + '/leaderboard'
        },
    },
}
</script>

<style lang="sass" scoped>
header
    img
        max-height: 175px
        &.howto
            max-height: 160px
            // margin-top: 20px
section
    // background: radial-gradient(black, transparent)
    img
        max-height: 170px
        margin: -2rem 0 2rem 0
    p
        font-weight: 600
footer
    // justify-content: center
    span
        display: flex
        justify-content: center
        display: inline-flex
    button
        width: 36%
        &.score::after
            background-image: url('/imgs/icon-menu.svg')
        &.howto
            width: 32%
    p
        margin-top: 4rem
        color: white
        font-size: 2rem
        font-weight: 600
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import data from '../data/index.yml'
Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        data: data,
        loading: false,
        refresh: 0,
        view: 'intro',
        level: 0,
        levelPage: 0,
        levelWon: false,
        gif: '',
        disabled: false,
        isIntro: true,
        isHowto: false,
        isResult: false,
        last: '',
        lastThree: [],
        matched: 0,
        selected: 0,
        timerState: 'ready',
        timerString: '',
        startTime: 0,
        pauseTime: 0,
        gaData: {
            event: 'roundEnd',
            roundCompleted: '',
            roundTime: '',
            value: 1,
        },
        gaStateData: {
            event: 'gameState',
            gameState: ''
        },
    },
    getters: {
        loading: state => state.loading,
        introScreenData: state => state.data.introScreen,
        resultScreenData: state => state.data.resultScreen,
        levelIntroScreenDataLevel1: state => state.data.levelIntroScreen.level1,
        levelIntroScreenDataLevel2: state => state.data.levelIntroScreen.level2,
        levelIntroScreenDataLevel3: state => state.data.levelIntroScreen.level3,
        gameScreenDataLevel1: state => state.data.gameScreen.level1,
        gameScreenDataLevel2: state => state.data.gameScreen.level2,
        gameScreenDataLevel3: state => state.data.gameScreen.level3
    },
    mutations: {
        handleMatch(state, event) {
            // first selection
            if (state.selected === 0) {
                state.selected++
                    state.last = event.currentTarget.dataset.tile
                const target = event.currentTarget
                    // flip
                event.currentTarget.classList.remove('none')
                event.currentTarget.classList.add('flip')
                setTimeout(() => {
                    target.classList.remove('over')
                }, 200)
            }
            // second selection
            else if (state.selected === 1) {
                state.selected++
                    const target = event.currentTarget
                let current = event.currentTarget.id
                event.currentTarget.classList.remove('none')
                event.currentTarget.classList.add('flip')
                setTimeout(() => {
                        target.classList.remove('over')
                    }, 200)
                    // yaml data match = last
                if (
                    state.data.gameScreen['level' + state.level].tiles[
                        current
                    ].match === state.last
                ) {
                    // correct match
                    state.timerState = 'paused' // --> TIMER PAUSE
                    state.matched++
                        const currentGif =
                            state.data.gameScreen['level' + state.level].tiles[
                                current
                            ].result
                    state.gif = currentGif
                    if (
                        state.matched === 6
                    ) {
                        // win level / show result
                        setTimeout(() => {
                            console.log('level win!')
                            state.levelWon = true
                            state.timerState = 'stopped' // --> TIMER STOPPED
                            this.commit('handleView', 'show-result')
                            this.commit('GA_DATAPUSH')
                        }, 2250)
                    } else {
                        // show result
                        setTimeout(() => {
                            console.log('correct!')
                            currentGif
                                ?
                                this.commit('handleView', 'show-result') :
                                (state.timerState = 'restart') // --> TIMER RESTART
                        }, 2250)
                    }
                    // update tiles
                    setTimeout(() => {
                        document
                            .querySelector(`[data-tile=${state.last}]`)
                            .classList.add('correct', 'blink')
                        document
                            .querySelector(`#${current}`)
                            .classList.add('correct', 'blink')
                    }, 500)
                    state.disabled = true // -- > !
                    setTimeout(() => {
                        state.disabled = false // -- > !
                    }, 2000)

                    // reset
                    state.selected = 0
                } else {
                    // incorrect
                    console.log('incorrect!')
                        // reset
                    setTimeout(() => {
                        document
                            .querySelector(`[data-tile=${state.last}]`)
                            .classList.add('incorrect', 'blink')
                        document
                            .querySelector(`#${current}`)
                            .classList.add('incorrect', 'blink')
                    }, 500)

                    state.disabled = true // -- > !
                    setTimeout(() => {
                        state.disabled = false // -- > !
                        document
                            .querySelector(`[data-tile=${state.last}]`)
                            .classList.add('over')
                        document
                            .querySelector(`[data-tile=${state.last}]`)
                            .classList.remove('flip', 'incorrect', 'blink')
                        document
                            .querySelector(`#${current}`)
                            .classList.add('over')
                        document
                            .querySelector(`#${current}`)
                            .classList.remove('flip', 'incorrect', 'blink')
                    }, 1000)
                    state.selected = 0
                }
            }
        },
        handleView(state, val) {
            switch (val) {
                case 'next-howto':
                    state.isIntro = false
                    state.isHowto = true
                    break
                case 'next-level-intro':
                    state.loading = true
                    state.view = 'level'
                    state.level++
                        state.levelPage++
                        setTimeout(() => {
                            state.loading = false
                        }, 150)
                    break
                case 'next-level-page':
                    state.loading = true
                    state.levelPage++
                        setTimeout(() => {
                            state.loading = false
                        }, 150)
                    break
                case 'next-game':
                    state.view = 'game'
                    state.levelWon = false
                    setTimeout(() => {
                        state.level == 1 ?
                            (state.timerState = 'running', this.commit('GA_GAMESTATEPUSH', 'Started')) :
                            (state.timerState = 'restart')
                    }, 100)
                    break
                case 'next-score':
                    state.view = 'score'
                    break
                case 'close-result':
                    state.isResult = false
                    state.levelWon ? null : (state.timerState = 'restart')
                    break
                case 'show-result':
                    state.isResult = true
                    break
                case 'show-form':
                    state.view = 'form'
                    state.isResult = false
                    break
                case 'next-level':
                    state.loading = true
                    state.view = 'level'
                    state.isIntro = false
                    state.isResult = false
                    state.levelWon ? null : (state.timerState = 'restart')
                    state.level++
                        state.levelPage = 1
                    state.matched = 0
                    state.selected = 0
                    state.last = ''
                    state.gif = ''
                    setTimeout(() => {
                        state.loading = false
                    }, 150)
                    break
                case 'next-restart':
                    state.loading = false
                    state.view = 'intro'
                    state.level = 0
                    state.levelPage = 0
                    state.levelWon = false
                    state.gif = ''
                    state.disabled = false
                    state.isIntro = true
                    state.isHowto = false
                    state.isResult = false
                    state.matched = 0
                    state.selected = 0
                    state.last = ''
            }
        },
        timerState(state, val) {
            state.timerState = val
        },
        timerStart(state, val) {
            state.startTime = val
        },
        timerPause(state, val) {
            state.pauseTime = val
        },
        timerStringUpdate(state, val) {
            state.timerString = val
        },
        GA_DATAPUSH(state) {
            state.gaData.roundComplete = 'Round ' + state.level + ' Complete'
            state.gaData.roundTime = state.timerString
            window.dataLayer.push(state.gaData)
        },
        GA_GAMESTATEPUSH(state, gameState) {
            state.gaStateData.gameState = gameState
            window.dataLayer.push(state.gaStateData)
        }
    },
    actions: {
        handleView: ({ commit }, val) => commit('handleView', val),
        handleMatch: ({ commit }, event) => {
            commit('handlematch', event)
        },
        setTimerState: ({ commit }, val) => commit('timerState', val),
        setStartTime: ({ commit }, val) => commit('timerStart', val),
        setPauseTime: ({ commit }, val) => commit('timerPause', val),
        setTimeString: ({ commit }, val) => commit('timerStringUpdate', val)
    }
})